import React, { useState } from 'react'
import portrait from "../Assets/createbanner.png";
import engagement from "../Assets/jrbanner.png";
import './Programs.css'

export default function Programs() {
    const[myPortrait, setMyPortrait]= useState(false);
    const[myEngagement, setMyEngagement]= useState(false);
    const[defaultPortrait, setDefaultPortrait]= useState(true);
    const[defaultEngagement, setDefaultEngagement]= useState(true);


  return (
    <div>
      <div className="portfolio"
        onMouseLeave={() => {
            setMyPortrait(false);
            setMyEngagement(false);
            setDefaultEngagement(true);
            setDefaultPortrait(true);
        }}>
        <h2>Main Programs</h2>
      
      <div className="portfolio-sections" >

        {defaultPortrait && (<div className="portrait-lifestyle"
        onMouseOver={() => setMyPortrait(true)}
        onMouseEnter={() => setDefaultEngagement(false)}>
          <a href="https://www.codeninjas.com/ca-cerritos/create" target="blank"> 
          <img src={portrait} alt="Create Program" />
          </a>

          
          
        </div>)}

        {myEngagement && (<div className="engagement-menu">
        <h3>JR Program (Ages 5-7)</h3>
        <p>For our young inspiring coders, we offer the JR program, where our ninjas work in a classrom-style environment building programs on tablets while also learning STEM fundamentals with our circuits and robotics. Click for more information!</p>

            </div>)}

        {myPortrait && (
            <div className="portrait-reveal"
            onMouseLeave={() => setMyPortrait(false)}>
                
            </div>
        )}  
        
            {defaultEngagement && (<a href="https://www.codeninjas.com/ca-cerritos/jr" target="blank"><div className="Engagement-work"
            onMouseOver={() => setMyEngagement(true)}
            onMouseEnter={() => setDefaultPortrait(false)}
            >
                
                <img src={engagement} alt="engagement" />
                
          
            </div></a>)}

            {myPortrait && (<div className="portrait-menu">
                <h3>CREATE Program (Ages 8+)</h3>
                <p>Here our Ninjas enter our belt system from white belt all the way to black belt on their adventure in game design. Along the way they will master the popular web development language, Javascript as well as the Windows application programming language, C#. Click for more information!</p>
                
            </div>)}

            {myEngagement && (
                <div className="engagement-reveal"
                onMouseLeave={() => {setMyEngagement(false); setDefaultPortrait(true)}}>
                    
                </div>
            )}  
      </div>

            {defaultPortrait && defaultEngagement && (<div className="portfolio-backgrounds">
                <div className="portrait-background"></div>
                <div className="Engagement-background"></div>
            </div>)}
        </div>
        <div className="portfolio-mobile">
          <div className="create-mobile-info">
            <a href="https://www.codeninjas.com/ca-cerritos/create" target="blank"> 
              <img src={portrait} alt="Create Program" />
            </a>
            <h3>CREATE Program (Ages 8+)</h3>
            <p>Here our Ninjas enter our belt system from white belt all the way to black belt on their adventure in game design. Along the way they will master the popular web development language, Javascript as well as the Windows application programming language, C#. Click for more information!</p>
          </div>
          <div className="create-mobile-info">
            <a href="https://www.codeninjas.com/ca-cerritos/jr" target="blank">
              <img src={engagement} alt="engagement" />
            </a>
            <h3>JR Program (Ages 5-7)</h3>
            <p>For our young inspiring coders, we offer the JR program, where our ninjas work in a classrom-style environment building programs on tablets while also learning STEM fundamentals with our circuits and robotics. Click for more information!</p>

          </div>
        </div>
    </div>
  )
}