import { Routes, Route } from "react-router-dom"; 
import Nav from './Components/Nav'
import Camps from './Camps';
import Contact from './Pages/Contact';
import Homepage from './Pages/Homepage';
import "./App.css";

function App() {

  const debounce = (fn) => {
    let frame;
    return (...params) => {
      if (frame) { 
        cancelAnimationFrame(frame);
      }
      frame = requestAnimationFrame(() => {
        fn(...params);
      });
  
    } 
  };
  
  
  // Reads out the scroll position and stores it in the data attribute
  // so we can use it in our stylesheets
  const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
  }
  
  // Listen for new scroll events, here we debounce our `storeScroll` function
  document.addEventListener('scroll', debounce(storeScroll), { passive: true });
  
  // Update scroll position for first time
  storeScroll();

  return (
    <div className="App">
      <Nav/>
      <Routes>
        <Route path='/' element={<Homepage/>} />
        <Route path='/camps' element={<Camps/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>  
      
    </div>  
  );
}

export default App;
