import React, { useRef, useEffect, useState } from 'react'
import "./Contact.css";
import emailjs from '@emailjs/browser';
import useScript from "../Components/useScript";
import icon1 from "../Assets/icon1.png";
import icon2 from "../Assets/icon2.png";
import icon3 from "../Assets/icon3.png";
import landscape from "../Assets/landscape2.png"
import logobrand from "../Assets/general-splash.png"

export default function Contact() {

  useScript('https://www.google.com/recaptcha/api.js');

  const [message, setMessage] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");
    const [message6, setMessage6] = useState("");
    

    const handleChange = event => {
        setMessage(event.target.value);
      };
    const handleChange2 = event => {
        setMessage2(event.target.value);
      };
      const handleChange3 = event => {
        setMessage3(event.target.value);
      };
      const handleChange4 = event => {
        setMessage4(event.target.value);
      };
      const handleChange5 = event => {
        setMessage5(event.target.value);
      };
      const handleChange6 = event => {
        setMessage6(event.target.value);
      };



  useEffect(() => {
    document.title = "Contact Us!";
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cv9pphz', 'template_eukwh3b', form.current, 'KinKq7UV0237y8IW8')
      .then((result) => {
          console.log(result.text);
          alert("Email sent! You'll hear back from me soon!");
          document.location.reload();
      }, (error) => {
          console.log(error.text);
          alert("NOT SENT! Make sure all fields are valid and the reCAPTCHA challenge is completed, and try again.")
          
      });
  };




  return (
    <div className="contact">
      
        <div className="logo-brand">
            <img src={logobrand} alt="code ninjas" />
            <div className="contact-cta">
                <h1>Contact Us Today!</h1>
            </div>
        </div>
        <div className="getintouch2">
            <div className="hide-mobile">
              <h2>Want to know more? We'll reach out to you!</h2>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="name-email">
                <div className="textfield">
                  <label for="name">First Name</label>
                  <input type="text" id="first-name" name="from_name" value={message} onChange={handleChange} required  />
                </div>
                <div className="textfield">
                  <label for="email">Last Name</label>
                  <input type="text" id="email" name="from_lname" value={message2} onChange={handleChange2} required  />
                </div>
              </div>
              <div className="name-email">
                <div className="textfield">
                  <label for="name">Phone Number</label>
                  <input type="text" id="phone" name="from_phone" value={message3} onChange={handleChange3} required  />
                </div>
                <div className="textfield">
                  <label for="email">Email Address</label>
                  <input type="text" id="email" name="from_email" value={message4} onChange={handleChange4} required  />
                </div>
              </div>
              <div className="name-email">
                <div className="textfield">
                  <label for="name">Child's First Name</label>
                  <input type="text" id="cname" name="from_cname" value={message5} onChange={handleChange5} required  />
                </div>
                <div className="textfield">
                  <label for="email">Child's Last Name</label>
                  <input type="text" id="clname" name="from_clname" value={message6} onChange={handleChange6} required  />
                </div>
              </div>
              <div className="selection">
                <label for="interest">Program</label>
                <select id="interest" name="from_path"  >
                  <option value="CREATE">CREATE (Ages 8+)</option>
                  <option value="JR">JR (Ages 5-7)</option>
                  <option value="Camps">Camps</option>
                </select>
              </div>
             
              
              <div className="submit">
                <div class="g-recaptcha" data-sitekey="6LeHZ48pAAAAAM3_TqYKh_CfeoMcNYsmwA6RykV3"></div>
                <br />
                <button disabled={message==="" || message2==="" || message4.length<7 || message3==="" || message4==="" || message3.length<10} type="submit">Request Info</button>
              </div>
            </form>  
            <div className="contact-methods">
                    <div className="contact-item">
                      <img src={icon1} alt="email" />  
                      <p>cerritosca@codeninjas.com</p>
                    </div>
                    <div className="contact-item">
                      <img src={icon2} alt="phone" />    
                      <p>(562)732-2633</p>
                    </div>
                    <div className="contact-item"> 
                      <img src={icon3} alt="instagram" />  
                      <p>codeninjascerritos</p>
                    </div>
            </div>
        </div>
        

        <div className="contact-splash">
            <img src={landscape} alt="contact" />
        </div>

      
    </div>
  )
}