import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./CampsMobile.css"
import AM from "../Assets/2.svg"
import PM from "../Assets/1.svg"
import one from "../Assets/Icons/stop motion animation.svg"
import two from "../Assets/Icons/coding arcade tycoon.svg"
import three from "../Assets/Icons/become a youtuber.svg"
import four from "../Assets/Icons/mm redstone realm.svg"
import five from "../Assets/Icons/ninja robotics w lego.svg"
import six from "../Assets/Icons/mc modding masters.svg"
import seven from "../Assets/Icons/intro to roblox dev.svg"
import eight from "../Assets/Icons/future minds ai academy.svg"
import nine from "../Assets/Icons/roblox world creators.svg"
import ten from "../Assets/Icons/3d design and print.svg"
import eleven from "../Assets/Icons/cc esports.svg"
import twelve from "../Assets/Icons/blockbuilders.svg"
import thirteen from "../Assets/Icons/adventures in gd.svg"
import fifteen from "../Assets/Icons/3d design and print.svg"
import sixteen from "../Assets/Icons/creative design studio.svg"
import seventeen from "../Assets/Icons/jr inventors.svg"
import eighteen from "../Assets/Icons/jr adventures in gd.svg"
import stopMotion from "../Assets/CNF 2024 Summer Camp Descriptions/stop motion animation.png"
import codingTycoon from "../Assets/CNF 2024 Summer Camp Descriptions/coding arcade tycoon.png"
import youtuber from "../Assets/CNF 2024 Summer Camp Descriptions/become a youtuber.png"
import redstone from "../Assets/CNF 2024 Summer Camp Descriptions/mc redstone realm.png"
import ninja from "../Assets/CNF 2024 Summer Camp Descriptions/ninja robotics with lego.png"
import minecraft from "../Assets/CNF 2024 Summer Camp Descriptions/mc modding masters.png"
import roblox from "../Assets/CNF 2024 Summer Camp Descriptions/intro to roblox.png"
import AI from "../Assets/CNF 2024 Summer Camp Descriptions/future minds ai academy.png"
import robloxWorld from "../Assets/CNF 2024 Summer Camp Descriptions/roblox world creators.png"
import design from "../Assets/CNF 2024 Summer Camp Descriptions/3d print and design.png"
import esports from "../Assets/CNF 2024 Summer Camp Descriptions/cc esports.png"
import adventure from "../Assets/CNF 2024 Summer Camp Descriptions/adventures in gd.png"
import dojoDesign from "../Assets/CNF 2024 Summer Camp Descriptions/dojo design 3d printing.png"
import studio from "../Assets/CNF 2024 Summer Camp Descriptions/creative design studio.png"
import jrStem from "../Assets/CNF 2024 Summer Camp Descriptions/JR inventors workshop.png"
import jrAdventure from "../Assets/CNF 2024 Summer Camp Descriptions/JR adventures in gd.png"
import beginner from "../Assets/beginners.svg"
import blockBuilders from "../Assets/CNF 2024 Summer Camp Descriptions/Block_Builders.svg"

export default function CampsMobile() {

    var amButton = (<img src={AM} alt="clickable AM icon" />);
    var pmButton = (<img src={PM} alt="clickable PM icon" />); 
  
    const [hovering, setHovering] = useState(false); 
    const [campSource, setCampSource] = useState(null);
  
    const handleMouseOver = (campSourceProp) => {
      setHovering(false);
      if (hovering){
        console.log(campSource);
      }
    }
  
    const handleMouseLeave = () => {
      setHovering(false)
    }

  return (
    <div className="mobile-layout">
        <Tabs>
            <TabList>
                <Tab>June</Tab>
                <Tab>July</Tab>
                <Tab>August</Tab>
            </TabList>
            <TabPanel>

          <div className="grid-layout-mobile">
            <div className="item1">
              <h1>CAMPS</h1>
            </div>
              <h5>JUN<br></br> 3-7</h5>
              <h5>JUN<br></br> 10-14</h5>
              <h5>JUN<br></br> 17-21</h5>
              <h5>JUN<br></br> 24-28</h5>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(stopMotion);}} onMouseLeave={handleMouseLeave}>
                <img src={one} alt="camp icon" />
                <h4>STOP MOTION ANIMATION</h4>
                
              </div>

              <a href="https://cn.mystudio.io/e/?=5627322633/297/110876//1706573537" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(codingTycoon);}} onMouseLeave={handleMouseLeave}><img src={two} alt="camp icon" /><h4>CODING ARCADE TYCOON</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89210//1706573525" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(youtuber);}} onMouseLeave={handleMouseLeave}><img src={three} alt="camp icon" /><h4>BECOME A YOUTUBER</h4><img src={beginner} alt="beginner friendly" /></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89218//1707521113" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(redstone);}} onMouseLeave={handleMouseLeave}><img src={four} alt="camp icon" /><h4>MINECRAFT MASTERY: REDSTONE REALM</h4></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89214//1706571523" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(ninja);}} onMouseLeave={handleMouseLeave}><img src={five} alt="camp icon" /><h4>NINJA ROBOTICS WITH LEGO</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116648//1708477143" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(minecraft);}} onMouseLeave={handleMouseLeave}><img src={six} alt="camp icon" /><h4>MINECRAFT® MODDING MASTERS</h4></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116599//1708462568" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(roblox);}} onMouseLeave={handleMouseLeave}><img src={seven} alt="camp icon" /><h4>INTRO TO ROBLOX DEVELOPMENT</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116636//1708473001" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(AI);}} onMouseLeave={handleMouseLeave}><img src={eight} alt="camp icon" /><h4>FUTURE MINDS AI ACADEMY</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89215//1706571762" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>

              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(robloxWorld);}} onMouseLeave={handleMouseLeave}><img src={nine} alt="camp icon" /><h4>ROBLOX WORLD CREATORS</h4></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116637//1708477595" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(design);}} onMouseLeave={handleMouseLeave}><img src={ten} alt="camp icon" /><h4>3D PRINT AND DESIGN</h4></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/110877//1707427724" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(dojoDesign);}} onMouseLeave={handleMouseLeave}><img src={fifteen} alt="camp icon" /><h4>DOJO DESIGN & 3D PRINTING (10+)</h4></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116627//1708471865" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(studio);}} onMouseLeave={handleMouseLeave}><img src={sixteen} alt="camp icon" /><h4>CREATIVE DESIGN STUDIO</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89216//1706573613" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(adventure);}} onMouseLeave={handleMouseLeave}><img src={thirteen} alt="camp icon" /><h4>ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/119037//1710369929" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(esports);}} onMouseLeave={handleMouseLeave}><img src={eleven} alt="camp icon" /><h4>CODE COMBAT: ESPORTS & GAME DESIGN</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/112800//1710288584" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrStem);}} onMouseLeave={handleMouseLeave}><img src={seventeen} alt="camp icon" /><h4>JR INVENTORS WORKSHOP</h4></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89207//1708476886" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrAdventure);}} onMouseLeave={handleMouseLeave}><img src={eighteen} alt="camp icon" /><h4>JR ADVENTURES IN GAME DESIGN</h4></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116647//1708477042" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(blockBuilders);}} onMouseLeave={handleMouseLeave}><img src={twelve} alt="camp icon" /><h4>BLOCK BUILDERS ADVENTURE CAMP</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>


          

          </div>
            </TabPanel>

            <TabPanel>
            <div className="grid-layout-mobile">

            
            <div className="item1">
              <h1>CAMPS</h1>
            </div>
              
              <h5>JUL<br></br> 8-12</h5>
              <h5>JUL<br></br> 15-19</h5>
              <h5>JUL<br></br> 22-26</h5>
              <h5>JUL 29-AUG 2</h5>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(stopMotion);}} onMouseLeave={handleMouseLeave}>
                <img src={one} alt="camp icon" />
                <h4>STOP MOTION ANIMATION</h4>
                
              </div>

              
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89219//1706571909" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(codingTycoon);}} onMouseLeave={handleMouseLeave}><img src={two} alt="camp icon" /><h4>CODING ARCADE TYCOON</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>
         

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(youtuber);}} onMouseLeave={handleMouseLeave}><img src={three} alt="camp icon" /><h4>BECOME A YOUTUBER</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116651//1708478235" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
             
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(redstone);}} onMouseLeave={handleMouseLeave}><img src={four} alt="camp icon" /><h4>MINECRAFT MASTERY: REDSTONE REALM</h4></div>
              
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116643//1709930804" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>
             
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(ninja);}} onMouseLeave={handleMouseLeave}><img src={five} alt="camp icon" /><h4>NINJA ROBOTICS WITH LEGO</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89211//1706574358" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(minecraft);}} onMouseLeave={handleMouseLeave}><img src={six} alt="camp icon" /><h4>MINECRAFT® MODDING MASTERS</h4></div>
              
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89213//1706573912" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/118274//1709931781" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(roblox);}} onMouseLeave={handleMouseLeave}><img src={seven} alt="camp icon" /><h4>INTRO TO ROBLOX DEVELOPMENT</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89229//1706571860" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/110880//1706574063" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
             

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(AI);}} onMouseLeave={handleMouseLeave}><img src={eight} alt="camp icon" /><h4>FUTURE MINDS AI ACADEMY</h4></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116642//1708473880" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
             
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(robloxWorld);}} onMouseLeave={handleMouseLeave}><img src={nine} alt="camp icon" /><h4>ROBLOX WORLD CREATORS</h4></div>
              
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116640//1708473670" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(design);}} onMouseLeave={handleMouseLeave}><img src={ten} alt="camp icon" /><h4>3D PRINT AND DESIGN</h4></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(dojoDesign);}} onMouseLeave={handleMouseLeave}><img src={fifteen} alt="camp icon" /><h4>DOJO DESIGN & 3D PRINTING (10+)</h4></div>
              
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89217//1706572001" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
            

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(studio);}} onMouseLeave={handleMouseLeave}><img src={sixteen} alt="camp icon" /><h4>CREATIVE DESIGN STUDIO</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
             

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(adventure);}} onMouseLeave={handleMouseLeave}><img src={thirteen} alt="camp icon" /><h4>ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/89225//1708478690" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(esports);}} onMouseLeave={handleMouseLeave}><img src={eleven} alt="camp icon" /><h4>CODE COMBAT: ESPORTS & GAME DESIGN</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrStem);}} onMouseLeave={handleMouseLeave}><img src={seventeen} alt="camp icon" /><h4>JR INVENTORS WORKSHOP</h4></div>
              
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116652//1708478492" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
            

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrAdventure);}} onMouseLeave={handleMouseLeave}><img src={eighteen} alt="camp icon" /><h4>JR ADVENTURES IN GAME DESIGN</h4></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/110878//1707174373" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>
           

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(blockBuilders);}} onMouseLeave={handleMouseLeave}><img src={twelve} alt="camp icon" /><h4>BLOCK BUILDERS ADVENTURE CAMP</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <a href="https://cn.mystudio.io/e/?=5627322633/297/116639//1708473676" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=5627322633/297/118275//1709931208" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              

         

  

</div>
            </TabPanel>

            <TabPanel>
            <div className="grid-layout-mobile">

            <div className="item1">
              <h1>CAMPS</h1>
            </div>
          
              <h5>AUG<br></br> 5-9</h5>
              <h5>X</h5>
              <h5>X</h5>
              <h5>X</h5>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(stopMotion);}} onMouseLeave={handleMouseLeave}>
                <img src={one} alt="camp icon" />
                <h4>STOP MOTION ANIMATION</h4>
                
              </div>

              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(codingTycoon);}} onMouseLeave={handleMouseLeave}><img src={two} alt="camp icon" /><h4>CODING ARCADE TYCOON</h4><img src={beginner} alt="beginner friendly" /></div>

              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(youtuber);}} onMouseLeave={handleMouseLeave}><img src={three} alt="camp icon" /><h4>BECOME A YOUTUBER</h4><img src={beginner} alt="beginner friendly" /></div>

              <a href="https://cn.mystudio.io/e/?=5627322633/297/116635//1708477398" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(redstone);}} onMouseLeave={handleMouseLeave}><img src={four} alt="camp icon" /><h4>MINECRAFT MASTERY: REDSTONE REALM</h4></div>

              <a href="https://cn.mystudio.io/e/?=5627322633/297/116653//1708478817" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(ninja);}} onMouseLeave={handleMouseLeave}><img src={five} alt="camp icon" /><h4>NINJA ROBOTICS WITH LEGO</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(minecraft);}} onMouseLeave={handleMouseLeave}><img src={six} alt="camp icon" /><h4>MINECRAFT® MODDING MASTERS</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(roblox);}} onMouseLeave={handleMouseLeave}><img src={seven} alt="camp icon" /><h4>INTRO TO ROBLOX DEVELOPMENT</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(AI);}} onMouseLeave={handleMouseLeave}><img src={eight} alt="camp icon" /><h4>FUTURE MINDS AI ACADEMY</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(robloxWorld);}} onMouseLeave={handleMouseLeave}><img src={nine} alt="camp icon" /><h4>ROBLOX WORLD CREATORS</h4></div>

              <a href="https://cn.mystudio.io/e/?=5627322633/297/89212//1706572094" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(design);}} onMouseLeave={handleMouseLeave}><img src={ten} alt="camp icon" /><h4>3D PRINT AND DESIGN</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(dojoDesign);}} onMouseLeave={handleMouseLeave}><img src={fifteen} alt="camp icon" /><h4>DOJO DESIGN & 3D PRINTING (10+)</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(studio);}} onMouseLeave={handleMouseLeave}><img src={sixteen} alt="camp icon" /><h4>CREATIVE DESIGN STUDIO</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(adventure);}} onMouseLeave={handleMouseLeave}><img src={thirteen} alt="camp icon" /><h4>ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(esports);}} onMouseLeave={handleMouseLeave}><img src={eleven} alt="camp icon" /><h4>CODE COMBAT: ESPORTS & GAME DESIGN</h4></div>
              
              <a href="https://cn.mystudio.io/e/?=5627322633/297/118272//1710288575" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrStem);}} onMouseLeave={handleMouseLeave}><img src={seventeen} alt="camp icon" /><h4>JR INVENTORS WORKSHOP</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrAdventure);}} onMouseLeave={handleMouseLeave}><img src={eighteen} alt="camp icon" /><h4>JR ADVENTURES IN GAME DESIGN</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(blockBuilders);}} onMouseLeave={handleMouseLeave}><img src={twelve} alt="camp icon" /><h4>BLOCK BUILDERS ADVENTURE CAMP</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

          
              </div>
            </TabPanel>
        </Tabs>
    </div>
  )
}
