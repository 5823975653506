import React from 'react'
import "./CampItem.css"

export default function CampItem(props) {

    

  return (
    <div className="camp-description">
        <img src={props.source} alt="camp description" />
    </div>
  )
}
